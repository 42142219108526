import { useMemo, useState } from "react"
import { add, addDays } from "date-fns"
import { MINIMUM_LISTING_DURATION_IN_MINUTES } from "@/constants"
import { SelectOption } from "@/design-system/Select"
import { useTranslate } from "@/hooks/useTranslate"
import { UnreachableCaseError } from "@/lib/helpers/type"

export type DateRange = { start: Date; end: Date }

export type DateRangeOptionsVariant =
  | "quick-listing"
  | "full-listing"
  | "collection-offer"
  | "trait-offer"
  | "single-item-offer"
  | "deal-offer"

type DefaultExpirationOption = {
  value: string
  getDefaultStartDate: () => Date
  getDefaultEndDate: () => Date
  getDefaultMinDate: () => Date
  getDefaultMaxDate: () => Date
}

const DEFAULT_SINGLE_ITEM_EXPIRATION_OPTION: DefaultExpirationOption = {
  value: "3d",
  getDefaultStartDate: () => new Date(),
  getDefaultEndDate: () => add(new Date(), { days: 3 }),
  getDefaultMinDate: () => add(new Date(), { hours: 12 }),
  getDefaultMaxDate: () => add(new Date(), { months: 6 }),
}

const DEFAULT_COLLECTION_EXPIRATION_OPTION: DefaultExpirationOption = {
  value: "7d",
  getDefaultStartDate: () => new Date(),
  getDefaultEndDate: () => add(new Date(), { days: 7 }),
  getDefaultMinDate: () => add(new Date(), { minutes: 30 }),
  getDefaultMaxDate: () => add(new Date(), { months: 1 }),
}

const DEFAULT_LISTING_EXPIRATION_OPTION: DefaultExpirationOption = {
  value: "1m",
  getDefaultStartDate: () => new Date(),
  getDefaultEndDate: () => add(new Date(), { months: 1 }),
  getDefaultMinDate: () =>
    add(new Date(), { minutes: MINIMUM_LISTING_DURATION_IN_MINUTES }),
  getDefaultMaxDate: () => add(new Date(), { months: 6 }),
}

const DEFAULT_DEAL_EXPIRATION_OPTION: DefaultExpirationOption = {
  value: "1d",
  getDefaultStartDate: () => new Date(),
  getDefaultEndDate: () => add(new Date(), { days: 1 }),
  getDefaultMinDate: () => add(new Date(), { minutes: 30 }),
  getDefaultMaxDate: () => add(new Date(), { months: 1 }),
}

export const useDateRangeOptions = (variant: DateRangeOptionsVariant) => {
  const t = useTranslate("orders")

  const formatMins = (minutes: number) => {
    return t(
      "dateRangeOptions.mins",
      {
        "0": `${minutes} minutes`,
        one: `${minutes} minute`,
        other: `${minutes} minutes`,
      },
      {
        count: minutes,
        minutes,
      },
      { forceString: true },
    )
  }

  const formatHours = (hours: number) => {
    return t(
      "dateRangeOptions.hours",
      {
        "0": `${hours} hours`,
        one: `${hours} hour`,
        other: `${hours} hours`,
      },
      {
        count: hours,
        hours,
      },
      { forceString: true },
    )
  }

  const formatDays = (days: number) => {
    return t(
      "dateRangeOptions.days",
      {
        "0": `${days} days`,
        one: `${days} day`,
        other: `${days} days`,
      },
      {
        count: days,
        days,
      },
      { forceString: true },
    )
  }

  const formatMonths = (months: number) => {
    return t(
      "dateRangeOptions.months",
      {
        "0": `${months} months`,
        one: `${months} month`,
        other: `${months} months`,
      },
      {
        count: months,
        months,
      },
      { forceString: true },
    )
  }

  let expirationOptions: SelectOption[]

  switch (variant) {
    case "single-item-offer": {
      expirationOptions = [
        {
          label: t("dateRangeOptions.customDate", "Custom"),
          value: "custom",
          date: undefined,
        },
        {
          label: formatHours(12),
          value: "12h",
          date: add(new Date(), { hours: 12 }),
        },
        {
          label: formatDays(1),
          value: "1d",
          date: add(new Date(), { days: 1 }),
        },
        {
          label: formatDays(3),
          value: "3d",
          date: add(new Date(), { days: 3 }),
        },
        {
          label: formatDays(7),
          value: "7d",
          date: add(new Date(), { days: 7 }),
        },
        {
          label: formatMonths(1),
          value: "1m",
          date: add(new Date(), { months: 1 }),
        },
        {
          label: formatMonths(3),
          value: "3m",
          date: add(new Date(), { months: 3 }),
        },
        {
          label: formatMonths(6),
          value: "6m",
          date: add(new Date(), { months: 6 }),
        },
      ]
      break
    }
    case "trait-offer":
    case "collection-offer": {
      expirationOptions = [
        {
          label: t("dateRangeOptions.customDate", "Custom"),
          value: "custom",
          date: undefined,
        },
        {
          label: formatMins(30),
          value: "30min",
          date: add(new Date(), { minutes: 30 }),
        },
        {
          label: formatHours(1),
          value: "1h",
          date: add(new Date(), { hours: 1 }),
        },
        {
          label: formatHours(6),
          value: "6h",
          date: add(new Date(), { hours: 6 }),
        },
        {
          label: formatDays(1),
          value: "1d",
          date: add(new Date(), { days: 1 }),
        },
        {
          label: formatDays(3),
          value: "3d",
          date: add(new Date(), { days: 3 }),
        },
        {
          label: formatDays(7),
          value: "7d",
          date: add(new Date(), { days: 7 }),
        },
        {
          label: formatMonths(1),
          value: "1m",
          date: add(new Date(), { months: 1 }),
        },
      ]
      break
    }
    case "deal-offer": {
      expirationOptions = [
        {
          label: formatMins(30),
          value: "30min",
          date: add(new Date(), { minutes: 30 }),
        },
        {
          label: formatHours(1),
          value: "1h",
          date: add(new Date(), { hours: 1 }),
        },
        {
          label: formatHours(6),
          value: "6h",
          date: add(new Date(), { hours: 6 }),
        },
        {
          label: formatDays(1),
          value: "1d",
          date: add(new Date(), { days: 1 }),
        },
        {
          label: formatDays(3),
          value: "3d",
          date: add(new Date(), { days: 3 }),
        },
        {
          label: formatDays(7),
          value: "7d",
          date: add(new Date(), { days: 7 }),
        },
        {
          label: formatMonths(1),
          value: "1m",
          date: add(new Date(), { months: 1 }),
        },
      ]
      break
    }
    case "quick-listing":
    case "full-listing": {
      expirationOptions = [
        {
          label: t("dateRangeOptions.customDate", "Custom"),
          value: "custom",
          date: undefined,
        },
        {
          label: formatHours(1),
          value: "1h",
          date: add(new Date(), { hours: 1 }),
        },
        {
          label: formatHours(6),
          value: "6h",
          date: add(new Date(), { hours: 6 }),
        },
        {
          label: formatDays(1),
          value: "1d",
          date: add(new Date(), { days: 1 }),
        },
        {
          label: formatDays(3),
          value: "3d",
          date: add(new Date(), { days: 3 }),
        },
        {
          label: formatDays(7),
          value: "7d",
          date: add(new Date(), { days: 7 }),
        },
        {
          label: formatMonths(1),
          value: "1m",
          date: add(new Date(), { months: 1 }),
        },
        {
          label: formatMonths(3),
          value: "3m",
          date: add(new Date(), { months: 3 }),
        },
        {
          label: formatMonths(6),
          value: "6m",
          date: add(new Date(), { months: 6 }),
        },
      ]
      break
    }
    default: {
      throw new UnreachableCaseError(variant)
    }
  }

  const getDefaultOptionHelper = (variant: DateRangeOptionsVariant) => {
    switch (variant) {
      case "single-item-offer": {
        return DEFAULT_SINGLE_ITEM_EXPIRATION_OPTION
      }
      case "collection-offer":
      case "trait-offer": {
        return DEFAULT_COLLECTION_EXPIRATION_OPTION
      }
      case "quick-listing":
      case "full-listing": {
        return DEFAULT_LISTING_EXPIRATION_OPTION
      }
      case "deal-offer": {
        return DEFAULT_DEAL_EXPIRATION_OPTION
      }
      default: {
        throw new UnreachableCaseError(variant)
      }
    }
  }

  const [endDateOptionValue, setEndDateOptionValue] = useState<string>(
    () => getDefaultOptionHelper(variant).value,
  )

  const defaultMinDate = useMemo(
    () => getDefaultOptionHelper(variant).getDefaultMinDate(),
    [variant],
  )

  const defaultMaxDate = useMemo(
    () => getDefaultOptionHelper(variant).getDefaultMaxDate(),
    [variant],
  )

  const defaultEndDate = useMemo(
    () => getDefaultOptionHelper(variant).getDefaultEndDate(),
    [variant],
  )

  const getDefaultAuctionExpiration = () => addDays(new Date(), 7)

  return {
    expirationOptions,
    getDefaultAuctionExpiration,
    defaultMaxDate,
    defaultMinDate,
    defaultEndDate,
    endDateOptionValue,
    setEndDateOptionValue,
  }
}
