import React from "react"
import { addMinutes } from "date-fns"
import { MINIMUM_LISTING_DURATION_IN_MINUTES } from "@/constants/index"
import { Block } from "@/design-system/Block"
import { DatePicker } from "@/design-system/DatePicker"
import { Flex } from "@/design-system/Flex"
import { FormControl } from "@/design-system/FormControl"
import { Select } from "@/design-system/Select"
import { useTranslate } from "@/hooks/useTranslate"
import {
  DateRange,
  DateRangeOptionsVariant,
  useDateRangeOptions,
} from "../hooks/useDateRangeOptions"

type Props = {
  duration?: DateRange
  id: string
  onChange: (value: DateRange | undefined) => unknown
  variant: DateRangeOptionsVariant
}

export const OrderDurationRangeSelect = ({
  duration,
  id,
  onChange,
  variant,
}: Props) => {
  const t = useTranslate("orders")

  const {
    expirationOptions,
    defaultMaxDate,
    defaultMinDate,
    endDateOptionValue,
    setEndDateOptionValue,
    defaultEndDate,
  } = useDateRangeOptions(variant)

  return (
    <>
      <FormControl
        hideLabel
        label={t("duration.rangeComponent.select", "Select duration")}
      >
        <Select
          clearable={false}
          id={id || "order-duration-range-select"}
          options={expirationOptions}
          overrides={{
            Dropdown: {
              props: {
                overflowY: "scroll",
                placement: "bottom",
                popperOptions: {
                  modifiers: [
                    {
                      name: "flip",
                      enabled: true,
                    },
                  ],
                },
              },
            },
          }}
          readOnly
          value={endDateOptionValue}
          onSelect={value => {
            if (value) {
              setEndDateOptionValue(value.value)
              onChange({
                start: new Date(),
                end: value.date ?? defaultEndDate,
              })
            }
          }}
        />
      </FormControl>

      {endDateOptionValue === "custom" && (
        <Flex alignItems="center" marginTop="16px">
          <Block flexGrow={1} minWidth={0}>
            <FormControl
              hideLabel
              label={t("duration.rangeComponent.start", "Start Date/Time")}
            >
              <DatePicker
                max={defaultMaxDate}
                min={defaultMinDate}
                overrides={{
                  Popover: {
                    placement: "bottom",
                    popperOptions: {
                      modifiers: [
                        {
                          name: "flip",
                          enabled: true,
                        },
                      ],
                    },
                  },
                  Button: {
                    id: "start-date-duration-picker-button",
                    style: {
                      fontWeight: 400,
                      width: "100%",
                      height: "48px",
                    },
                  },
                  Input: {
                    inputId: "start-date-duration-picker-input",
                  },
                }}
                value={duration ? new Date(duration.start) : undefined}
                withTime
                onChange={value => {
                  onChange({
                    start: value,
                    end:
                      duration?.end ||
                      addMinutes(
                        new Date(),
                        MINIMUM_LISTING_DURATION_IN_MINUTES,
                      ),
                  })
                }}
              />
            </FormControl>
          </Block>
          <Block marginX="16px">-</Block>
          <Block flexGrow={1} minWidth={0}>
            <FormControl
              hideLabel
              label={t("duration.rangeComponent.end", "End Date/Time")}
            >
              <DatePicker
                max={defaultMaxDate}
                min={defaultMinDate}
                overrides={{
                  Popover: {
                    placement: "bottom",
                    popperOptions: {
                      modifiers: [
                        {
                          name: "flip",
                          enabled: true,
                        },
                      ],
                    },
                  },
                  Button: {
                    id: "end-date-duration-picker-button",
                    style: {
                      fontWeight: 400,
                      width: "100%",
                      height: "48px",
                    },
                  },
                  Input: {
                    inputId: "end-date-duration-picker-input",
                  },
                }}
                value={duration ? new Date(duration.end) : undefined}
                withTime
                onChange={value => {
                  onChange({
                    start: duration?.start || new Date(),
                    end: value,
                  })
                }}
              />
            </FormControl>
          </Block>
        </Flex>
      )}
    </>
  )
}
